@tailwind base;
@tailwind components;
@tailwind utilities;


.popup {
    transition: .5s ease-in-out;


    opacity: 0;
    transform: scale(0.95);

    z-index: -1;

}

.popup.popup-open {

    opacity: 1;
    transform: scale(1);

    z-index: 10;

}

.modal {
    animation: modal-opacity-open .3s ease-in-out;
}

.modal.closing {
    animation-name: modal-opacity-close;
    
}

@keyframes modal-opacity-open {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes modal-opacity-close {
    from {opacity: 1;}
    to {opacity: 0;}
}
