
.drawer {
    position: absolute;
    height: 100%;
    left: -100%;

    transition: .5s ease-in-out;
}

.drawer.drawer-open {
    left: 0 !important;
}

.drawer > .drawer-greyout > div {
    opacity: 0;
    transition: .5s ease-in-out;

}

.drawer.drawer-open > .drawer-greyout > div {
    opacity: 0.75;
}
